<template>
  <SDrawer :title="$t('pendingAccounts.addMaterial')" :visible.sync="visible" @confirm="handleConfirm" :showFooterCancel="false"
    custom-class="confirm_id_info_drawer">
    <StatusAlert :title="$t('pendingAccounts.tip')" type="warning" show-icon :closable="false">
      <div class="alert_description">
        <a :href="'mailto:' + GLOBAL_ACCOUNT_EMAIL">{{ GLOBAL_ACCOUNT_EMAIL }}</a>
      </div>
    </StatusAlert>
    <div class="info_box">
      <p class="title">{{ $t('pendingAccounts.primaryDoc') }}</p>
      <div class="info_content">
        <img src="@/assets/images-1/id.png" alt="">
        <ul>
          <li>{{ $t('pendingAccounts.passport') }}</li>
          <li>{{ $t('pendingAccounts.id') }}</li>
          <li>{{ $t('pendingAccounts.dl') }}</li>
        </ul>
      </div>
    </div>
    <div class="info_box">
      <p class="title">{{ $t('pendingAccounts.address') }}</p>
      <div class="info_content">
        <img src="@/assets/images-1/poa.png" alt="">
        <ul>
          <li v-for="(list, index) in $t('pendingAccounts.addressList')" :key="index">
            {{ list }}
          </li>
        </ul>
      </div>
    </div>
  </SDrawer>
</template>

<script>
import StatusAlert from '@/components/customUI/StatusAlert.vue'

export default {
  components: {
    StatusAlert
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    openDrawder() {
      this.visible = true;
    },
    handleConfirm() {
      this.visible = false;
    }
  }

};
</script>

<style lang="scss" scoped>
.title {
  margin: 24px 0 16px;
  font-size: 14px;
  color: $text-secondary;
  line-height: 22px;
}

img {
  margin-bottom: 8px;
}

.info_content {

  /deep/ ul {
    li {
      font-size: 14px;
      color: $text-primary;
      line-height: 22px;
    }
  }
}

.alert_description {
  a {
    color: $warning;
  }
}
</style>
