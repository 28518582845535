<template>
  <div>
    <div v-show="selectedCard == 'neverFunded'">
      <el-table ref="traderTable" :data="neverFundedDisplayData" style="width: 100%;" :fit="true">
        <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="120">
          <template slot-scope="traderTableScope">
            {{ traderTableScope.row.date | date('DD/MM/YYYY') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.keys.ACCNUM')" min-width="140">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.accounts" :key="index">
              <u>
                <a @click.stop.prevent="showDetail(item)" data-testid="showDetail">
                  {{ item }}
                </a>
              </u>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('common.keys.NAME')"></el-table-column>
        <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>
        <el-table-column prop="accountType" :label="$t('common.keys.ACCTYPE')" column-key="accountType" min-width="120">
          <template slot-scope="traderTableScope">
            {{ $config.accountTypeMaps[traderTableScope.row.accountType] }}
          </template>
        </el-table-column>
        <el-table-column prop="platform" :label="$t('common.keys.PLATFORM')" column-key="platform" min-width="120">
          <template slot-scope="traderTableScope">{{ 'MT' + traderTableScope.row.platform }}</template>
        </el-table-column>
        <el-table-column prop="baseCurrency" :label="$t('common.keys.BASECURRENCY')" min-width="140"></el-table-column>
        <el-table-column prop="accountBalance" :label="$t('common.keys.BALANCE')" min-width="120">
          <template slot-scope="traderTableScope"> {{ traderTableScope.row.accountBalance | currency('') }}</template>
        </el-table-column>
        <el-table-column prop="profit" :label="$t('common.keys.PROFITLOST')" min-width="120">
          <template slot-scope="traderTableScope"> {{ traderTableScope.row.profit | currency('') }}</template>
        </el-table-column>
        <el-table-column prop="marginLevel" :label="$t('common.keys.MARGINLVL') + '(%)'" min-width="120">
          <template slot-scope="traderTableScope"> {{ traderTableScope.row.marginLevel | currency('') }}</template>
        </el-table-column>
        <el-table-column prop="equity" :label="$t('common.keys.ACCEQUITY')" min-width="180">
          <template slot-scope="traderTableScope"> {{ traderTableScope.row.equity | currency('') }}</template>
        </el-table-column>
        <el-table-column prop="credit" :label="$t('common.keys.CREDIT')" min-width="120">
          <template slot-scope="traderTableScope"> {{ traderTableScope.row.credit | currency('') }}</template>
        </el-table-column>
      </el-table>
      <FakePagination :raw-data="tableNeverFunded" :display-data.sync="neverFundedDisplayData"></FakePagination>
    </div>
    <div v-show="selectedCard == 'zeroBalance'">
      <el-table ref="traderTable" :data="zeroBalanceDisplayData" style="width: 100%;">
        <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="120">
          <template slot-scope="traderTableScope">
            {{ traderTableScope.row.date | date('DD/MM/YYYY') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.keys.ACCNUM')" min-width="140">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.accounts" :key="index">
              <u>
                <a @click.stop.prevent="showDetail(item)" data-testid="showDetail">
                  {{ item }}
                </a>
              </u>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('common.keys.NAME')" min-width="120"></el-table-column>
        <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>
        <el-table-column prop="accountType" :label="$t('common.keys.ACCTYPE')" column-key="accountType" min-width="120">
          <template slot-scope="traderTableScope">
            {{ $config.accountTypeMaps[traderTableScope.row.accountType] }}
          </template>
        </el-table-column>
        <el-table-column prop="platform" :label="$t('common.keys.PLATFORM')" column-key="platform" min-width="120">
          <template slot-scope="traderTableScope">{{ 'MT' + traderTableScope.row.platform }}</template>
        </el-table-column>
        <el-table-column prop="baseCurrency" :label="$t('common.keys.BASECURRENCY')" min-width="140"></el-table-column>
        <el-table-column prop="accountBalance" :label="$t('common.keys.BALANCE')" min-width="120">
          <template slot-scope="traderTableScope">
            {{ traderTableScope.row.accountBalance | currency('') }}
          </template>
        </el-table-column>
        <el-table-column prop="profit" :label="$t('common.keys.PROFITLOST')" min-width="120">
          <template slot-scope="traderTableScope">
            {{ traderTableScope.row.profit | currency('') }}
          </template>
        </el-table-column>
        <el-table-column prop="marginLevel" :label="$t('common.keys.MARGINLVL') + '(%)'" min-width="120">
          <template slot-scope="traderTableScope">
            {{ traderTableScope.row.marginLevel | currency('') }}
          </template>
        </el-table-column>
        <el-table-column prop="equity" :label="$t('common.keys.ACCEQUITY')" min-width="180">
          <template slot-scope="traderTableScope">
            {{ traderTableScope.row.equity | currency('') }}
          </template>
        </el-table-column>
        <el-table-column prop="credit" :label="$t('common.keys.CREDIT')" min-width="120">
          <template slot-scope="traderTableScope">
            {{ traderTableScope.row.credit | currency('') }}
          </template>
        </el-table-column>
      </el-table>
      <FakePagination :raw-data="tableZeroBalance" :display-data.sync="zeroBalanceDisplayData"></FakePagination>
    </div>
  </div>
</template>

<script>
import FakePagination from '@/components/elementUI/Pagination/FakePagination';
import { apiGetAccsByUrl } from '@/resource';

export default {
  name: 'unfundedAccounts',
  components: { FakePagination },
  props: { selectedCard: String, unfundedAccountsObject: Object },
  data() {
    return {
      tableNeverFunded: [],
      tableZeroBalance: [],
      neverFundedDisplayData: [],
      zeroBalanceDisplayData: [],
      userId: this.$store.state.common.CUID,
      tableIndex: 0
    };
  },
  watch: {
    selectedCard() {
      this.getSelectedCardApi();
    },
    unfundedAccountsObject: {
      handler(unfundedAccountsObject) {
        if (unfundedAccountsObject.IBAccount && unfundedAccountsObject.subIBAccount) this.getSelectedCardApi();
      },
      deep: true
    }
  },
  methods: {
    getSelectedCardApi() {
      if (this.selectedCard === 'neverFunded') this.getNeverFundedAccounts();
      else if (this.selectedCard === 'zeroBalance') this.getZeroBalanceAccounts();
    },
    getZeroBalanceAccounts() {
      const url = 'zerobalanceUnderIB';
      this.getAccounts(this.userId, url).then(resp => {
        if (resp.data.code === 0 && resp.data.data.length >= 0) {
          this.tableZeroBalance = resp.data.data;
        }
      });
    },
    getNeverFundedAccounts() {
      const url = 'neverfundedUnderIB';
      this.getAccounts(this.userId, url).then(resp => {
        if (resp.data.code === 0 && resp.data.data.length >= 0) {
          this.tableNeverFunded = resp.data.data;
        }
      });
    },
    getAccounts(userId, url) {
      const data = { userId: userId, ...this.unfundedAccountsObject };
      return apiGetAccsByUrl(url, data);
    },
    showDetail(index, row) {
      this.$emit('showDetail', index, row);
    }
  }
};
</script>
