<template>
  <div>
    <PagePanel>
      <PageSearchArea :showButton="false">
        <template #form>
          <el-form label-position="top">
            <AccountNumber></AccountNumber>
            <el-form-item :label="$t('ibAccounts.subIbs')">
              <!-- v-if="subIbAccountList != undefined && subIbAccountList.length > 0" -->
              <el-cascader expand-trigger="hover" :options="subIbAccountList" v-model="subIbAccount" change-on-select
                @change="handleChange" filterable :show-all-levels="false" @visible-change="handleItemChange"
                style="width: 100%;"></el-cascader>
            </el-form-item>
          </el-form>
        </template>
      </PageSearchArea>
    </PagePanel>
    <PagePanel :tabs="selectedCardList" :tabActive.sync="selectedCard">
      <div>
        <div v-show="selectedCard == 'activatedAccounts'">
          <el-table ref="traderTable" :data="
            traderTableData | columnFilter(filters, setFilteredTotal) | tableDataPageFilter(pageNo, size)
          " style="width: 100%;" @sort-change="sortChange" @filter-change="handleFilterChange"
            :row-key="traderTableData != undefined ? traderTableData.accountNmber : ' '">
            <el-table-column prop="date" :label="$t('common.keys.DATE')" sortable="custom" min-width="100">
              <template slot-scope="traderTableScope">
                {{ traderTableScope.row.date | date('DD/MM/YYYY') }}
              </template>
            </el-table-column>
            <el-table-column prop="accountNmber" :label="$t('common.keys.ACCNUM')" min-width="140">
              <template slot-scope="traderTableScope">
                <u>
                  <a @click.stop.prevent="showDetail(traderTableScope.row.accountNmber)" data-testid="showDetail">
                    {{ traderTableScope.row.accountNmber }}
                  </a>
                </u>
              </template></el-table-column>
            <el-table-column prop="name" :label="$t('common.keys.NAME')" min-width="100">
              <template slot-scope="traderTableScope">
                <span v-html="traderTableScope.row.name" />
              </template>
            </el-table-column>
            <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="140"></el-table-column>
            <el-table-column prop="accountType" :label="$t('common.keys.ACCTYPE')" :filters="accountTypeOptions"
              column-key="accountType" min-width="120">
              <template slot-scope="traderTableScope">
                {{ $config.accountTypeMaps[traderTableScope.row.accountType] }}
              </template>
            </el-table-column>
            <el-table-column prop="platform" :label="$t('common.keys.PLATFORM')" :filters="platformOptions"
              column-key="platform" min-width="120">
              <template slot-scope="traderTableScope">{{ 'MT' + traderTableScope.row.platform }}</template>
            </el-table-column>
            <el-table-column prop="baseCurrency" :label="$t('common.keys.BASECURRENCY')" :filters="currencyOptions"
              column-key="baseCurrency" min-width="140"></el-table-column>
            <el-table-column prop="accountBalance" :label="$t('common.keys.BALANCE')" sortable="custom" min-width="120"
              :sort-orders="['ascending', 'descending']">
              <template slot-scope="traderTableScope">
                {{ traderTableScope.row.accountBalance | currency('') }}
              </template>
            </el-table-column>
            <el-table-column prop="profit" :label="$t('common.keys.PROFITLOST')" sortable="custom" min-width="180"
              :sort-orders="['ascending', 'descending']">
              <template slot-scope="traderTableScope">
                {{ traderTableScope.row.profit | currency('') }}
              </template>
            </el-table-column>
            <el-table-column prop="marginLevel" :label="$t('common.keys.MARGINLVL') + '(%)'" sortable="custom"
              min-width="180" :sort-orders="['ascending', 'descending']">
              <template slot-scope="traderTableScope">
                {{ traderTableScope.row.marginLevel | currency('') }}
              </template>
            </el-table-column>
            <el-table-column prop="equity" :label="$t('common.keys.ACCEQUITY')" sortable="custom" min-width="180"
              :sort-orders="['ascending', 'descending']">
              <template slot-scope="traderTableScope">
                {{ traderTableScope.row.equity | currency('') }}
              </template>
            </el-table-column>
            <el-table-column prop="credit" :label="$t('common.keys.CREDIT')" sortable="custom" min-width="180"
              :sort-orders="['ascending', 'descending']">
              <template slot-scope="traderTableScope">
                {{ traderTableScope.row.credit | currency('') }}
              </template>
            </el-table-column>
          </el-table>
          <SPagination :total="filteredTotal" @current-change="handleCurrentChange" @size-change="handleSizeChange"
            :current-page.sync="pageNo"></SPagination>
        </div>
        <UnfundedAccounts :selectedCard="selectedCard" :unfundedAccountsObject="unfundedAccountsObject"
          @showDetail="showDetail"></UnfundedAccounts>
      </div>
    </PagePanel>
    <TradingList :tradingListVisible.sync="tradingListVisible" :accountNumber="accountDetails"></TradingList>
  </div>
</template>

<script>
import AccountNumber from '@/components/customUI/AccountNumber';
import UnfundedAccounts from './UnfundedAccounts';
import TradingList from './TradingList';
import { getSessionStorage, setSessionStorage, removeSessionStorage } from '@/util';
import { apiIbAccounts } from '@/resource';

export default {
  name: 'IbAccount',
  components: { AccountNumber, TradingList, UnfundedAccounts },
  data() {
    return {
      none: this.$t('common.keys.NONE'),
      all: this.$t('common.keys.ALL'),
      userId: this.$store.state.common.CUID,
      account: '',
      subIbAccountList: [],
      subIbAccount: [],
      selectedIbAccount: '',
      traderUrl: 'retail_clients',
      subIbUrl: 'sub_ibs',
      filteredTotal: 0,
      traderTableData: [],
      platformOptions: [],
      currencyOptions: [],
      pageNo: 1,
      size: 25,
      filters: {},
      accountTypeOptions: [],
      selectedCard: 'activatedAccounts',
      unfundedAccountsObject: { IBAccount: '', subIBAccount: 'none' },
      tradingListVisible: false,
      accountDetails: 0,
      selectedCardList: [
        { name: 'activatedAccounts', dataTestId: 'activatedAccounts', label: this.$t('unfundedAcc.allActivationAccs') },
        { name: 'neverFunded', dataTestId: 'neverFunded', label: this.$t('unfundedAcc.neverFundedAccs') },
        { name: 'zeroBalance', dataTestId: 'zeroBalance', label: this.$t('unfundedAcc.prevFundedZero') },
      ]
    };
  },
  watch: {
    ibAccount: {
      handler(newValue) {
        this.$nextTick(() => {
          this.unfundedAccountsObject.IBAccount = newValue;
          if (newValue) this.ibAccOnChange(newValue);
        });
      },
      immediate: true
    },
    traderTableData(newData) {
      this.buildFilterOptions();
    },
    selectedIbAccount(newAccount) {
      this.removeSortLabels();
      //clear table filters
      this.filters = {};
      this.$refs.traderTable.clearFilter();
      // console.log("value in selected ib account: ", newAccount);
      if (newAccount === this.all) {
        this.traderTableData = getSessionStorage('TTD' + newAccount + this.ibAccount);
        if (this.traderTableData) {
          return;
        }
        this.getIbAccounts(this.userId, this.ibAccount, 'all_clients').then(resp => {
          if (resp.data.code === 0) {
            this.traderTableData = resp.data.data;
            setSessionStorage('TTD' + newAccount + this.ibAccount, this.traderTableData);
          }
        });
        return;
      }
      if (newAccount === this.none) {
        this.traderTableData = getSessionStorage('TTD' + this.ibAccount);
        return;
      }
      this.traderTableData = getSessionStorage('TTD' + newAccount);
      if (this.traderTableData) {
        return;
      }
      this.getIbAccounts(this.userId, newAccount, this.traderUrl).then(resp => {
        if (resp.data.code === 0) {
          this.traderTableData = resp.data.data;
          setSessionStorage('TTD' + newAccount, this.traderTableData);
        }
      });
    }
  },
  methods: {
    ibAccOnChange(newValue) {
      //clear table filters
      this.filters = {};
      this.$refs.traderTable.clearFilter();

      this.selectedIbAccount = newValue;
      this.subIbAccount = ['none'];
      this.unfundedAccountsObject.subIBAccount = 'none';
      this.subIbAccountList = getSessionStorage('SUBIB' + newValue);
      //return if cached result is found and the result has child menu
      if (this.subIbAccountList && this.subIbAccountList.some(e => e.hasOwnProperty('children'))) {
        return;
      }
      //retrieve sub ib accounts under the current ib account
      this.getIbAccounts(this.userId, newValue, this.subIbUrl).then(resp => {
        if (resp.data.code === 0 && resp.data.data.length > 0) {
          this.subIbAccountList = this.buildCascaderOption(resp.data.data);
          this.subIbAccountList.forEach(e => {
            //retrieve the second level of sub ib accounts
            this.getIbAccounts(this.userId, this.getAccountFromLabel(e.label), this.subIbUrl).then(resp => {
              if (resp.data.code === 0 && resp.data.data.length > 0) {
                this.$set(e, 'children', this.buildCascaderOption(resp.data.data));
              }
            });
          });
          this.subIbAccountList.push({ value: 'all', label: this.all });
          this.subIbAccountList.unshift({ value: 'none', label: this.none });
          this.$nextTick(() => setSessionStorage('SUBIB' + newValue, this.subIbAccountList));
        }
      });
    },
    handleFilterChange(filters) {
      let [key] = Object.keys(filters);
      let flag = true; //check if it is a same filter. no update for same filters
      if (this.filters[key]) {
        if (filters[key].length === this.filters[key].length) {
          for (let i = 0, end = filters[key].length; i < end; i++) {
            if (filters[key][i] != this.filters[key][i]) {
              flag = false;
              break;
            }
          }
          if (flag) {
            return;
          }
        }
      }
      this.$set(this.filters, key, filters[key]);
    },
    // 排序
    sortChange(column) {
      if (column.column == null) {
        let key = 'TTD' + this.selectedIbAccount;
        if (this.selectedIbAccount === this.all) {
          key += this.ibAccount;
        }
        this.traderTableData = getSessionStorage(key);
        return;
      }
      this.traderTableData = getSessionStorage(
        'TTD' + this.ibAccount + this.selectedIbAccount + column.prop + column.order
      );
      if (this.traderTableData) {
        return;
      }
      let sortUrl = 'sort_clients';
      let account = this.selectedIbAccount;
      if (this.selectedIbAccount === this.all) {
        sortUrl = 'sort_all_clients';
        account = this.ibAccount;
      }
      this.getSortedTable(this.userId, account, column.prop, column.order === 'ascending' ? 1 : 0, sortUrl).then(
        resp => {
          if (resp.data.code === 0) {
            this.traderTableData = resp.data.data;
            setSessionStorage(
              'TTD' + this.ibAccount + this.selectedIbAccount + column.prop + column.order,
              this.traderTableData
            );
          }
        }
      );
    },
    removeSortLabels() {
      const sortElements = $('.el-table__header .is-leaf.is-sortable');
      for (let i = 0, end = sortElements.length; i < end; i++) {
        if (sortElements[i].classList.contains('descending')) {
          sortElements[i].click();
        } else if (sortElements[i].classList.contains('ascending')) {
          sortElements[i].click();
          sortElements[i].click();
        }
      }
    },
    handleCurrentChange(val) {
      this.pageNo = val;
    },
    handleSizeChange(size) {
      this.size = size;
    },
    handleItemChange(flag) {
      if (!flag) {
        let subIbAccount = this.getSelectedSubIb(this.subIbAccount);
        if (subIbAccount == undefined) {
          return;
        }
        switch (subIbAccount.label) {
          case this.all:
            this.selectedIbAccount = subIbAccount.label;
            this.unfundedAccountsObject.subIBAccount = 'all';
            break;
          case this.none:
            this.selectedIbAccount = this.ibAccount;
            this.unfundedAccountsObject.subIBAccount = 'none';
            break;
          default:
            this.selectedIbAccount = this.getAccountFromLabel(subIbAccount.label);
            console.log(this.selectedIbAccount);
            this.unfundedAccountsObject.subIBAccount = this.selectedIbAccount;
            break;
        }
      }
    },
    setFilteredTotal(total) {
      this.filteredTotal = total;
    },
    getAccountFromLabel(label) {
      let nameAndAccount;

      let nameAccountAndType = label.split(' - ');
      nameAndAccount = nameAccountAndType[0].split(' ');

      return nameAndAccount[nameAndAccount.length - 1];
    },
    buildCascaderOption(accountList) {
      let resultOption = [];
      accountList.forEach((e, i) => {
        let label = e.name + ' ' + e.account + ' - ' + this.$config.accountTypeMaps[e.accType];

        resultOption.push({
          value: i,
          label: label
        });
      });
      return resultOption;
    },
    getIbAccounts(userId, account, url) {
      return apiIbAccounts(url, {
        userId: userId,
        account: account
      });
    },
    getSortedTable(userId, account, sortingField, sortBy, url) {
      return apiIbAccounts(url, {
        userId: userId,
        account: account,
        sortingField: sortingField,
        sortBy: sortBy
      });
    },
    getSelectedSubIb(value) {
      //index 0 is "none"
      if (value.indexOf('none') > -1) {
        return this.subIbAccountList[0];
      }
      if (value.indexOf('all') > -1) {
        return this.subIbAccountList[this.subIbAccountList.length - 1];
      }
      let topLevelIndex = value[0] + 1;
      let selectedSubIb = this.subIbAccountList[topLevelIndex];
      // trace down to the last parent ib account according to passed-in value
      for (let i = 1, end = value.length; i < end; i++) {
        if (selectedSubIb.children) {
          selectedSubIb = selectedSubIb.children[value[i]];
        }
      }
      return selectedSubIb;
    },
    // 下拉菜单几级联动
    handleChange(value) {
      if (value.indexOf('none') > -1) {
        this.selectedIbAccount = this.ibAccount;
        return;
      }

      if (value.indexOf('all') > -1) {
        this.selectedIbAccount = this.all;
        return;
      }

      let curChild = this.getSelectedSubIb(value).children;
      if (curChild != undefined && !curChild.loaded) {
        curChild.forEach(e => {
          if (e.children == undefined) {
            //retrieve the second level of sub ib accounts
            this.getIbAccounts(this.userId, this.getAccountFromLabel(e.label), this.subIbUrl).then(resp => {
              if (resp.data.code === 0 && resp.data.data.length > 0) {
                this.$set(e, 'children', this.buildCascaderOption(resp.data.data));
              }
            });
          }
        });
        curChild.loaded = true;
        setSessionStorage('SUBIB' + this.ibAccount, this.subIbAccountList);
      }
    },
    buildFilterOptions() {
      this.accountTypeOptions = [];
      this.platformOptions = [];
      this.currencyOptions = [];
      let accountTypeSet = new Set();
      let platformSet = new Set();
      let currencySet = new Set();
      if (this.traderTableData == undefined) {
        return;
      }
      this.traderTableData.forEach(e => {
        accountTypeSet.add(e.accountType);
        platformSet.add(e.platform);
        currencySet.add(e.baseCurrency);
      });
      accountTypeSet.forEach(e => {
        this.accountTypeOptions.push({
          text: this.$config.accountTypeMaps[e],
          value: e
        });
      });
      platformSet.forEach(e => {
        this.platformOptions.push({
          text: 'MT' + e,
          value: e
        });
      });
      currencySet.forEach(e => {
        this.currencyOptions.push({
          text: e,
          value: e
        });
      });
    },
    clearSubibCache() {
      Object.keys(sessionStorage).forEach(e => {
        if (/^(SUBIB|TTD){1}[\s\S]*$/.test(e)) {
          removeSessionStorage(e);
        }
      });
    },
    showDetail(account) {
      this.accountDetails = account;
      this.tradingListVisible = true;
    }
  },
  created() {
    this.clearSubibCache();
  },
  filters: {
    columnFilter(tableData, filters, setFilteredTotal) {
      if (tableData == undefined) {
        return;
      }
      let keys = Object.keys(filters);
      if (keys.length > 0) {
        tableData = tableData.filter(element => {
          let flag = true;
          for (let i = 0, end = keys.length; i < end; i++) {
            if (filters[keys[i]] != undefined && filters[keys[i]].length > 0) {
              if (filters[keys[i]].indexOf(element[keys[i]]) < 0) {
                flag = false;
                break;
              }
            }
          }
          return flag;
        });
      }
      if (tableData) {
        setFilteredTotal(tableData.length);
      }
      return tableData;
    },
    tableDataPageFilter(tableData, pageNo, size) {
      if (tableData == undefined) {
        return;
      }
      let start = (pageNo - 1) * size;
      let end = pageNo * size;
      return tableData.slice(start, end);
    }
  },
  computed: {
    ibAccount() {
      return this.$store.state.behavior.AID;
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/leads.scss';
// @import '@/assets/css/components/pagination.scss';
// @import '@/assets/css/pages/ibAccounts.scss';
</style>
