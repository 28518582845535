<template>
  <SDialog :visible.sync="visible" class="trading_list_dialog" :title="$t('ibAccounts.tradinglist')" :showFooter="false"
    width="50%">
    <div name="openListTable" class="trading_list_table">
      <el-table ref="openListTable" style="width: 100%;" :data="openListTable"
        :row-key="openListTable != undefined ? openListTable.ticket : ' '">
        <el-table-column :label="$t('ibAccounts.openlist')">
          <el-table-column prop="ticket" :label="$t('ibAccounts.tradinglistColumn.ticketid')" min-width="100">
          </el-table-column>
          <el-table-column prop="openTime" :label="$t('ibAccounts.tradinglistColumn.opentime')" min-width="140">
          </el-table-column>
          <el-table-column prop="type" :label="$t('ibAccounts.tradinglistColumn.type')" min-width="100">
            <template slot-scope="traderTableScope">
              {{
                traderTableScope.row.type === 0
                ? $t('ibAccounts.tradinglistColumn.buy')
                : $t('ibAccounts.tradinglistColumn.sell')
              }}
            </template>
          </el-table-column>
          <el-table-column prop="volume" :label="$t('ibAccounts.tradinglistColumn.volume')"
            min-width="100"></el-table-column>
          <el-table-column prop="item" :label="$t('ibAccounts.tradinglistColumn.item')" column-key="item" min-width="120">
          </el-table-column>
          <el-table-column prop="openPrice" :label="$t('ibAccounts.tradinglistColumn.openprice')" column-key="openPrice"
            min-width="100">
          </el-table-column>
          <el-table-column prop="closePrice" :label="$t('ibAccounts.tradinglistColumn.closeprice')" min-width="100">
          </el-table-column>
          <el-table-column prop="commission" :label="$t('ibAccounts.tradinglistColumn.commission')" min-width="100">
          </el-table-column>
          <el-table-column prop="profit" :label="$t('ibAccounts.tradinglistColumn.profit')" min-width="100">
          </el-table-column>
        </el-table-column>
      </el-table>
      <SPagination :total="openListData.length" @current-change="handleOpenTableChange"
        @size-change="handleOpenTableSizeChange" :current-page.sync="openTableCurrentPage"
        layout="sizes, prev, pager, next">
      </SPagination>
    </div>

    <PageSearchArea buttonTestId="UPDATE" @queryData="searchClosedList">
      <template #form>
        <el-form label-position="top">
          <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate" :showLabel="false">
          </DateRangePicker>
        </el-form>
      </template>
    </PageSearchArea>

    <div name="closedListTable" class="trading_list_table">
      <el-table ref="closedListTable" style="width: 100%;" :data="closedListTable"
        :row-key="closedListTable != undefined ? closedListTable.ticket : ' '">
        <el-table-column :label="$t('ibAccounts.closedlist')">
          <el-table-column prop="ticket" :label="$t('ibAccounts.tradinglistColumn.ticketid')" min-width="100">
          </el-table-column>
          <el-table-column prop="openTime" :label="$t('ibAccounts.tradinglistColumn.opentime')" min-width="140">
          </el-table-column>
          <el-table-column prop="type" :label="$t('ibAccounts.tradinglistColumn.type')" min-width="100">
            <template slot-scope="traderTableScope">
              {{
                traderTableScope.row.type === 0
                ? $t('ibAccounts.tradinglistColumn.buy')
                : $t('ibAccounts.tradinglistColumn.sell')
              }}
            </template>
          </el-table-column>
          <el-table-column prop="volume" :label="$t('ibAccounts.tradinglistColumn.volume')"
            min-width="100"></el-table-column>
          <el-table-column prop="item" :label="$t('ibAccounts.tradinglistColumn.item')" column-key="item" min-width="120">
          </el-table-column>
          <el-table-column prop="openPrice" :label="$t('ibAccounts.tradinglistColumn.openprice')" column-key="openPrice"
            min-width="100">
          </el-table-column>
          <el-table-column prop="closeTime" :label="$t('ibAccounts.tradinglistColumn.closetime')" column-key="closeTime"
            min-width="140"></el-table-column>
          <el-table-column prop="closePrice" :label="$t('ibAccounts.tradinglistColumn.closeprice')" min-width="100">
          </el-table-column>
          <el-table-column prop="commission" :label="$t('ibAccounts.tradinglistColumn.commission')" min-width="100">
          </el-table-column>
          <el-table-column prop="profit" :label="$t('ibAccounts.tradinglistColumn.profit')" min-width="100">
          </el-table-column>
        </el-table-column>
      </el-table>
      <SPagination :total="closedListData.length" @current-change="handleClosedTableChange"
        @size-change="handleClosedTableSizeChange" :current-page.sync="closedTableCurrentPage"
        layout="sizes, prev, pager, next">
      </SPagination>
    </div>
  </SDialog>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';
import { apiGetOpenList, apiGetClosedList } from '@/resource';
import DateRangePicker from '@/components/customUI/DateRangePicker';

export default {
  props: {
    tradingListVisible: Boolean,
    accountNumber: Number
  },
  components: { LoadingButton, DateRangePicker },
  data() {
    return {
      visible: false,
      pageSize: 10,
      openTableCurrentPage: 1,
      closedTableCurrentPage: 1,
      startDate: '',
      endDate: '',
      openListData: [],
      openListTable: [],
      closedListData: [],
      closedListTable: []
    };
  },
  watch: {
    tradingListVisible(bool) {
      this.visible = bool;
      if (this.accountNumber != 0 && this.visible) this.searchOpenList();
    },
    visible(bool) {
      if (!bool) {
        this.closedListData = [];
        this.closedListTable = [];
        this.updateClosedTableData();
      }
      this.disableTradingListVisible(bool);
    }
  },
  methods: {
    disableTradingListVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:tradingListVisible', bool);
      });
    },
    searchClosedList() {
      this.getClosedList().then(resp => {
        if (resp.data.code === 0 && resp.data.data.length >= 0) {
          this.closedListData = resp.data.data;
          this.updateClosedTableData();
        }
      });
      return Promise.resolve();
    },
    searchOpenList() {
      this.getOpenList().then(resp => {
        if (resp.data.code === 0 && resp.data.data.length >= 0) {
          this.openListData = resp.data.data;
          this.updateOpenTableData();
        }
      });
    },
    getOpenList() {
      return apiGetOpenList({ account: this.accountNumber });
    },
    getClosedList() {
      return apiGetClosedList({
        account: this.accountNumber,
        startDateStr: this.startDate.split(' ')[0],
        endDateStr: this.endDate.split(' ')[0]
      });
    },
    handleOpenTableChange(pageNumber) {
      this.openTableCurrentPage = pageNumber;
      this.updateOpenTableData();
    },
    handleOpenTableSizeChange(size) {
      this.pageSize = size;
      this.updateOpenTableData();
    },
    handleClosedTableChange(pageNumber) {
      this.closedTableCurrentPage = pageNumber;
      this.updateClosedTableData();
    },
    handleClosedTableSizeChange(size) {
      this.pageSize = size;
      this.updateClosedTableData();
    },
    updateOpenTableData() {
      this.openListTable = this.openListData.slice(
        (this.openTableCurrentPage - 1) * this.pageSize,
        this.openTableCurrentPage * this.pageSize
      );
    },
    updateClosedTableData() {
      this.closedListTable = this.closedListData.slice(
        (this.closedTableCurrentPage - 1) * this.pageSize,
        this.closedTableCurrentPage * this.pageSize
      );
    }
  }
};
</script>

<style lang="scss">
// @import '@/assets/css/pages/tradingList.scss';
.trading_list_dialog {
  .trading_list_table:nth-child(1) {
    margin-bottom: 24px;
  }

  .search_form {
    margin-bottom: 0;
  }

  .search_button {
    padding-top: 0;
  }
}
</style>
