<template>
  <div>
    <PagePanel>
      <PageSearchArea :showButton="false">
        <template #form>
          <el-form label-position="top">
            <AccountNumber @accountCallback="queryLeads"></AccountNumber>
          </el-form>
        </template>
      </PageSearchArea>
    </PagePanel>
    <PagePanel :tabs="selectedCardList" :tabActive.sync="selectedCard" @tabClick="resetPage">
      <div>
        <div v-show="selectedCard === 'demoAccount'">
          <el-table :data="displayDemoLeads" style="width: 100%">
            <el-table-column :label="$t('common.keys.DATE')" min-width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.create_time | date('DD/MM/YYYY') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="real_name" :label="$t('common.keys.NAME')" min-width="120"></el-table-column>
            <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>
            <el-table-column :label="$t('common.keys.ACCTYPE')" min-width="120">
              <template slot-scope="scope">
                {{ $config.accountTypeMaps[scope.row.accountMT4Type] }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.keys.PLATFORM')" min-width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.platform | formatPlatform() }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.keys.BASECURRENCY')" min-width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.apply_currency | formatUppercase() }}</span>
              </template>
            </el-table-column>
          </el-table>
          <FakePagination ref="demoPagination" :raw-data="demoLeads" :display-data.sync="displayDemoLeads">
          </FakePagination>
        </div>
        <div v-show="selectedCard === 'leadsAccount'">
          <el-table :data="displayNormalLeads" style="width: 100%">
            <el-table-column :label="$t('common.keys.DATE')" min-width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.create_time | date('DD/MM/YYYY') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="real_name" :label="$t('common.keys.NAME')" min-width="120"></el-table-column>
            <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>
          </el-table>
          <FakePagination ref="normalPagination" :raw-data="normalLeads" :display-data.sync="displayNormalLeads">
          </FakePagination>
        </div>
      </div>
    </PagePanel>
  </div>
</template>

<script>
import AccountNumber from '@/components/customUI/AccountNumber';
import { apiLeads } from '@/resource';
import FakePagination from '@/components/elementUI/Pagination/FakePagination';

export default {
  name: 'Leads',
  components: { FakePagination, AccountNumber },
  data() {
    return {
      userID: this.$store.state.common.CUID,
      leads: [],
      demoLeads: [],
      displayDemoLeads: [],
      normalLeads: [],
      displayNormalLeads: [],
      selectedCardList: [
        { name: 'demoAccount', dataTestId: 'resetNormalPage', label: this.$t('lead.demoAccounts') },
        { name: 'leadsAccount', dataTestId: 'resetDemoPage', label: this.$t('lead.header') },
      ],
      selectedCard: 'demoAccount'
    };
  },
  methods: {
    queryLeads() {
      apiLeads({ userId: this.userID }).then(resp => {
        if (resp.status == 200) {
          this.leads = resp.data;
          this.updateLeads();
        }
      });
    },
    updateLeads() {
      this.demoLeads = [];
      this.normalLeads = [];
      this.leads.forEach(element => {
        if (element.affid != this.accountID) {
          return;
        }
        if (element.website_user_type == '1' && element.accountMT4Type) {
          this.demoLeads.push(element);
          return;
        }
        if (element.website_user_type == '8') {
          this.normalLeads.push(element);
        }
      });
      this.resetDemoPage();
      this.resetNormalPage();
    },
    resetDemoPage() {
      this.$refs.demoPagination.resetPage();
    },
    resetNormalPage() {
      this.$refs.normalPagination.resetPage();
    },
    resetPage() {
      if (this.selectedCard === 'demoAccount') {
        this.resetNormalPage();
      } else {
        this.resetDemoPage();
      }
    },
  },
  watch: {
    accountID() {
      this.updateLeads();
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/leads.scss';
</style>
