<template>
  <div class="pending_account">
    <StatusAlert type="warning" show-icon :closable="false">
      <div class="alert_description">
        {{ $t('pendingAccounts.errorDoc') }}
        <span @click="openConfirmIdDrawer">{{ $t('pendingAccounts.addMaterial') }}</span>
      </div>
    </StatusAlert>
    <PagePanel>
      <el-table :data="displayData" style="width: 100%;">
        <el-table-column :label="$t('common.keys.DATE')" min-width="120">
          <template slot-scope="scope">{{ scope.row.register_date | date }}</template>
        </el-table-column>
        <el-table-column prop="applyName" :label="$t('common.keys.NAME')" min-width="120"></el-table-column>
        <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>
        <el-table-column :label="$t('common.keys.ACCTYPE')" min-width="120">
          <template slot-scope="scope">
            {{ $config.accountTypeMaps[scope.row.account_type] }}
          </template>
        </el-table-column>
        <el-table-column prop="mt4_datasource" :label="$t('common.keys.PLATFORM')" min-width="120"></el-table-column>
        <el-table-column prop="apply_currency" :label="$t('common.keys.BASECURRENCY')" min-width="120"></el-table-column>
      </el-table>
      <FakePagination :raw-data="tableData" :display-data.sync="displayData"></FakePagination>
    </PagePanel>
    <ConfirmIdInfo ref="confirmIdInfo"></ConfirmIdInfo>
  </div>
</template>

<script>
import { apiGetPendingAccount } from '@/resource';
import FakePagination from '@/components/elementUI/Pagination/FakePagination';
import StatusAlert from '@/components/customUI/StatusAlert.vue'
import ConfirmIdInfo from './ConfirmIdInfo.vue'

export default {
  components: { FakePagination, StatusAlert, ConfirmIdInfo },
  data() {
    return {
      // 分页
      userID: this.$store.state.common.CUID,
      total: 0,
      displayData: [],
      tableData: []
    };
  },
  mounted() {
    this.queryPendingUser();
  },
  methods: {
    queryPendingUser() {
      apiGetPendingAccount({
        pid: this.userID
      }).then(response => {
        this.tableData = response.data;
        this.total = this.tableData.length;
      }, this.$handleError);
    },
    openConfirmIdDrawer() {
      this.$refs.confirmIdInfo.openDrawder()
    }
  }
};
</script>

<style lang="scss">
// @import '@/assets/css/pages/pendingAccounts.scss';
.pending_account {

  .el-alert--warning {
    margin-bottom: 16px;

    .el-alert__description {
      margin: 0;

      span {
        color: $text-link;
        cursor: pointer;
      }
    }
  }
}
</style>
