<template>
  <PageWrapper :tabs="tabs" :tabActive.sync="tabActive" class="account_manage">
    <keep-alive>
      <component :is="currentComponent" />
    </keep-alive>
  </PageWrapper>
</template> 

<script>
import IbAccounts from './components/IbAccounts.vue'
import PendingAccounts from './components/PendingAccounts.vue'
import Leads from './components/Leads.vue'

export default {
  name: 'accountManage',
  components: {
    IbAccounts,
    PendingAccounts,
    Leads,
  },
  data() {
    return {
      tabs: [
        { name: 'tab1', label: this.$t('menu.ibAccs') },
        { name: 'tab2', label: this.$t('menu.pendingAccs')},
        { name: 'tab3', label: this.$t('menu.leads')},
      ],
      tabActive: 'tab1',
    };
  },
  computed: {
    currentComponent() {
      if (this.tabActive === 'tab1') {
        return IbAccounts
      } else if (this.tabActive === 'tab2') {
        return PendingAccounts
      } else {
        return Leads
      } 
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
